import React, { Fragment } from "react"

import appStoreBadge from "../images/appstore_badge_black.svg"

export default function ProjectHightlight(props: ProjectHighlightProps) {
  let primaryColorString = "rgb(90, 90, 90)"

  return (
    <div className="row mt-5 mb-5">
      <div
        className={`text-center col-lg-5 ${
          props.imageOnRight ? "order-lg-2" : ""
        } mb-2 d-flex justify-content-center align-items-center`}
      >
        <img
          src={props.imageSource}
          alt="Project Highlight Image"
          style={{
            height: "300px",
            maxWidth: "100%",
          }}
        />
      </div>

      <div className={`col-lg-7 ${props.imageOnRight ? "order-lg-1" : ""}`}>
        <div className="row">
          <div className="col">
            <h2
              style={{
                fontSize: "50px",
                fontWeight: 300,
                lineHeight: "1",
                letterSpacing: "-1px",
                color: primaryColorString,
              }}
            >
              {props.title}
              <br />
              <span className="text-muted">{props.subtitle}</span>
            </h2>

            <p
              style={{
                fontSize: 21,
                color: primaryColorString,
                lineHeight: "1.5",
                fontWeight: 300,
              }}
            >
              {props.message}
            </p>

            {props.appStoreUrl && (
              <Fragment>
                <a
                  href={props.appStoreUrl}
                  target="_wine-racks"
                  className="mr-2"
                >
                  <img src={appStoreBadge} height="46px" />
                </a>
              </Fragment>
            )}

            {props.buttons?.map(data => {
              return (
                <a
                  className="btn btn-lg btn-primary mr-2"
                  href={data.url}
                  target="_projects-external"
                  role="button"
                >
                  {data.content}
                </a>
              )
            })}

            {props.technologyImageSources && (
              <Fragment>
                {props.technologyImageSources.map(tis => (
                  <img src={tis} height="46px" style={{ marginLeft: "10px" }} />
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

type ProjectHighlightProps = {
  title: string
  subtitle: string
  message: string
  imageOnRight: boolean
  imageSource: string
  buttons?: ProjectHighlightButtonData[]
  appStoreUrl?: string
  technologyImageSources?: string[]
}

type ProjectHighlightButtonData = {
  content: JSX.Element
  url: string
}
