import React, { Fragment } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectHightlight from "../components/project-highlight"
import { LinkExternalIcon } from "@primer/octicons-react"
import azureBootcamp2019Image from "../images/azure-global-bootcamp-2019.png"
import passengerAppScreenshot from "../images/PassengerAppScreenshot.jpg"
import reactIcon from "../images/react_icon.png"
import stockExchangeImage from "../images/stock-exchange-app.png"
import xprojectCalendar from "../images/xproject-calendar.png"
import froggerScreen from "../images/frogger-screen.png"
import gameOfLife from "../images/game-of-life.png"
import memoji from "../images/memoji.png"
import wineRacks from "../images/wine_glass2.svg"
import giftExchangeIcon from "../images/gift_box.svg"
import swiftUI from "../images/swiftui-icon.png"
import azureLogo from "../images/azure_logo.png"
import netCore from "../images/netcore_icon.svg"
import bootstrapIcon from "../images/bootstrap_icon.png"
import xamarinIcon from "../images/xamarin_icon.png"

export default function Projects() {
  return (
    <Layout>
      <SEO
        title="Projects"
        description="Personal project highlights by John VanSickle"
      />

      <ProjectHightlight
        imageOnRight={true}
        imageSource={wineRacks}
        title="Wine Racks"
        subtitle="The wine journal you've been missing."
        message='I wanted to learn SwiftUI, and couldn&apos;t find a good, simple iOS app to keep track of the wines that I bought and sampled. So in July 2020 I made my own. Now, after several iterations and improvements, the app has new, user-requested features such as "price" and a Widget to enjoy your wines on your home screen. Check it out from the App Store button below.'
        appStoreUrl="https://apps.apple.com/us/app/id1524155078"
        technologyImageSources={[swiftUI]}
      />

      <hr />

      <ProjectHightlight
        imageOnRight={false}
        imageSource={giftExchangeIcon}
        title="Gift Exchange Generator"
        subtitle="Distribute those names quickly."
        message="A quick applicaiton made in a morning after having had to rip up paper and write a dozen names on them to set up a Secret Santa gift exchange. I witnessed so many things go wroung such as people drawing their own name, picking their spouses name, and having to try to trade after picking a non-ideal name. I took that experience and made an application that will help make the experience much smoother next time around."
        appStoreUrl="https://apps.apple.com/us/app/id1542511740"
        technologyImageSources={[swiftUI]}
      />

      <hr />

      <ProjectHightlight
        imageOnRight={true}
        imageSource={memoji}
        title="This website!"
        subtitle="Now with Gatsby."
        message="This website was previously pure HTML, JS, and bootstrap with a carousel library. Now it's a lean, mean, React-backed Gatsby machine. Being able to have reusable React components, like these project highlights will be an enourmous time save, and encourage me to keep this website updated."
        technologyImageSources={[reactIcon, bootstrapIcon]}
      />

      <hr />

      <ProjectHightlight
        imageSource={azureBootcamp2019Image}
        imageOnRight={false}
        title="Azure Vision and Voice."
        subtitle="Exploring Capabilities."
        message="For my presentation at Microsoft's 2019 Global Azure Bootcamp I discussed some of Azure's Cognitive services. Specifically, I covered the Vision and Voice services. This project is the foundation of my talk; combining my experience in Xamarin.Forms with the Azure Cognitive libraries for a full-experience demo. Be sure to check out the closest Azure Bootcamp event to you!"
        buttons={[
          {
            url: "https://github.com/jvansickle/AzureVisionAndVoice",
            content: (
              <Fragment>
                Source Code <LinkExternalIcon verticalAlign="middle" />
              </Fragment>
            ),
          },
        ]}
        technologyImageSources={[netCore, xamarinIcon, azureLogo]}
      />

      <hr />

      <ProjectHightlight
        imageSource={passengerAppScreenshot}
        imageOnRight={true}
        title="Pinpoint Transit."
        subtitle="Always right on time."
        message="PinPoint Transit's Passenger App is designed for Clarion University students that use the public bus system. Like any transit, the bus is rarely on time, whether it be early or late. This app alleviates that concern by allowing passengers to see live data of where the bus is and when it should arrive at any assigned pickup."
      />

      <hr />

      <ProjectHightlight
        imageOnRight={false}
        imageSource={gameOfLife}
        title="And lastly, this one."
        subtitle="It's Game of Life."
        message="Game of Life is a simulation of a biome where individual units are born, live, and die depending on their circumstances. A fun little project from freshman year of college, it seems like such a simple algorithm to run."
        buttons={[
          {
            content: (
              <Fragment>
                Get the source <LinkExternalIcon />
              </Fragment>
            ),
            url: "https://gitlab.com/jwvansickle/game-of-life",
          },
        ]}
      />
    </Layout>
  )
}
